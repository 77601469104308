<template>
  <div class="card-container">
    <van-swipe class="my-swipe" @change="handleSwipeChange">
      <van-swipe-item v-for="(item, index) in transcript" :key="item">
        <div class="maskedText" :id="index" v-html="generateSentence(item, index)"></div>
      </van-swipe-item>
      <template #indicator="{ active, total }">
        <div class="custom-indicator">{{ active + 1 }}/{{ total }}</div>
      </template>
    </van-swipe>
  </div>
</template>

<script>
import { showToast } from 'vant';
import { mapMutations } from 'vuex';
//import { ref } from 'vue';

function timeStringToSeconds(timeString) {
  const parts = timeString.split(':');//parseInt函数在解析字符串时，会从字符串的开头开始解析，直到遇到一个不能转换为数字的字符为止
  let seconds = 0;
  for (let i = parts.length - 1; i >= 0; i--) {
    seconds += parseInt(parts[i]) * Math.pow(60, parts.length - 1 - i);
  }
  const outerParts = timeString.split(',');
  const milliseconds = parseInt(outerParts[1]) / 1000
  seconds += milliseconds
  return seconds;
}

export default {
  props: {
    options: {
      type: String, // 根据实际值的类型定义
      default: '',
    }
  },
  data() {
    return {
      contentLines: [],
      transcript: [],
    };
  },
  setup(props) {
    //console.log("SrtCloze.setup(props)", props.options);
    const onChange = (index) => showToast('当前 Swipe 索引：' + index);
    return { onChange };
  },
  methods: {
    setUnderlineStyle(input, correctValue){
      //console.log(input, correctValue)
      if (correctValue) {
        const inputWidth = `${correctValue.length * 20}px`
        if (input) {
          input.style.border = 'none';
          input.style.borderBottom = '1px solid #444';
          input.style.backgroundColor = 'transparent';
          //input.style.color = '#444';
          input.style.fontSize = '26px';
          input.style.width = inputWidth;
        }
      }
    },
    getCloze(index){
      //不这么写document.querySelectorAll('.maskedText');首先获取到的是一个空值
      //在 Vue 中，如果想在使用 v-html 渲染内容后执行某个方法，可以考虑使用 $nextTick 方法
      //$nextTick 的作用是在下次 DOM 更新循环结束之后执行延迟回调。当你使用 v-html 更新了 HTML 内容后，DOM 可能还没有立即更新，使用 $nextTick 可以确保在 DOM 更新后执行特定的逻辑。
      //先更新了 htmlContent 的值，然后使用 $nextTick 确保在 DOM 更新后执行 doSomethingAfterVHtmlUpdate 方法
      this.$nextTick(() => {
        // 这里可以执行你想要在 v-html 更新后执行的逻辑
        var maskedTexts = document.querySelectorAll(`.maskedText`);
        for (let i = 0; i < maskedTexts.length; i++) {
          if (maskedTexts[i].id == index) {
            const inputs = maskedTexts[i].querySelectorAll('input');
            inputs.forEach(input => {
              const inputId = input.id;
              const correctValue = this.transcript[index].text[inputId]
              this.setUnderlineStyle(input, correctValue);
              input.addEventListener('input', () => {
                //假如是第一句，input id是3和6，
                //那么就是和transcript里面，transcript[0].text[3]比
                const inputValue = input.value;
                const matchingValue = inputValue === correctValue
                if (matchingValue) {
                  input.style.color = 'green';
                  input.setAttribute('disabled', true);
                }
              });
            });
          }
        }
      });
    },
    generateSentence(item, index) {
      let sentence = "";
      for (let i = 0; i < item.text.length; i++) {
        if (item.randomIndexes && item.randomIndexes.includes(i)) {
          sentence += `<input id="${i}" type="text" class="underline-input"> `;
        } else {
          sentence += item.text[i] + " ";
        }
      }
      this.getCloze(index);
      return sentence.trim();
    },
    getRandomIndexes(sentence) {
      const words = this.getSplitText(sentence);
      const filteredText = words.map((word, index) => {
        if (this.isWordOnlyLetters(word)) {
          return {index, word}
        } else {
          return null
        }
      })
      const nonNullData = filteredText.filter(item => item!== null);
      if(nonNullData.length < 2){
        return null;
      }
      let word1, word2;
      do {
        word1 = nonNullData[Math.floor(Math.random() * nonNullData.length)];
        word2 = nonNullData[Math.floor(Math.random() * nonNullData.length)];
      } while (word1.index === word2.index);
      if (word1.index < word2.index) {
        return [word1.index, word2.index];
      } else {
        return [word2.index, word1.index];
      }
    },
    isWordOnlyLetters(word) {
      return /^[a-zA-Z]+$/.test(word);
    },
    getSplitText(sentence) {
      const words = sentence.split(' ');
      return words
    },
    ...mapMutations(['setCurrentItemId', "setCurrentItemStartTime", "setCurrentItemEndTime"]),
    handleSwipeChange(index) {
      this.currentIndex = index;
      const currentItem = this.transcript[index];
      if (currentItem) {
        this.setCurrentItemId(currentItem.id);
        this.setCurrentItemStartTime(currentItem.startTime);
        this.setCurrentItemEndTime(currentItem.endTime);
      }
    },
    async getTxtContentUsingFetch(options) {
      try {
        const response = await fetch(options.src);
        if (response.ok) {
          const content = await response.text();
          this.contentLines = content.split('\n');
          for (let i = 0; i < this.contentLines.length; i += 4) {
            if (this.contentLines[i] && this.contentLines[i + 1] && this.contentLines[i + 2]) {
              //console.log(this.contentLines[i]+"==="+this.contentLines[i + 1]+"==="+this.contentLines[i + 2])
              if (this.getSplitText(this.contentLines[i + 2]).length < 9 && i <= (this.contentLines.length - 8)) {
                this.transcript.push({
                  id: parseInt(this.contentLines[i]),
                  timeRange: this.contentLines[i + 1].split(' --> ')[0] + ' --> ' + this.contentLines[i + 5].split(' --> ')[1],
                  text: this.getSplitText(this.contentLines[i + 2].trim()).concat(this.getSplitText(this.contentLines[i + 6].trim())),
                  randomIndexes: this.getRandomIndexes(this.contentLines[i + 2].trim() + this.contentLines[i + 6].trim()),
                  startTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[0]),
                  endTime: timeStringToSeconds(this.contentLines[i + 5].split(' --> ')[1]),
                });
                i+=4
              }else{
                this.transcript.push({
                  id: parseInt(this.contentLines[i]),
                  timeRange: this.contentLines[i + 1],
                  text: this.getSplitText(this.contentLines[i + 2].trim()),
                  randomIndexes: this.getRandomIndexes(this.contentLines[i + 2].trim()),
                  startTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[0]),
                  endTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[1]),
                });
              }
            }
          }
          //设置初始值
          if (this.transcript.length > 0) {
            this.setCurrentItemId(this.transcript[0].id);
            this.setCurrentItemStartTime(this.transcript[0].startTime);
            this.setCurrentItemEndTime(this.transcript[0].endTime);
            //console.log("SrtCloze:this.contentLines[1].split(' --> ')[0])", timeStringToSeconds(this.contentLines[1].split(' --> ')[0]))
          }
        } else {
          console.error('无法获取 txt 文件内容');
        }
      } catch (error) {
        console.error('发生错误：', error);
      }
    }
  },
  mounted() {
    //console.log("SrtCloze:mounted()执行了")
    //console.log("this.options", this.options)
    this.getTxtContentUsingFetch(this.options);
    //this.getTxtContentUsingFetch(this.options);
  },
  name: "SrtCloze"
}
</script>

<style scoped>

/*.maskedText {
  font-family: Arial, sans-serif;
  font-size: 26px;
  color: #444;
  text-align: left;
  padding: 20px;
  background-color: #fff;
}*/
.maskedText {
  font-family: Arial, sans-serif;
  font-size: 26px;
  color: #444;
  text-align: left;
  padding: 20px;
  background-color: #fff;
}
.custom-indicator {
  position: absolute;
  right: 5px;
  bottom: 5px;
  padding: 2px 5px;
  font-size: 12px;
  background: rgba(0, 0, 0, 0.1);
}

.card-container {
  /* 可以添加一些外边距和阴影来突出卡片效果 */
  margin: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background: #ffffff;
  border-radius: 10px; /* 设置圆角半径为 10 像素，可以根据你的需求调整这个值 */
}

</style>