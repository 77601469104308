<template>
  <RouterView :key="routerViewKey"/>
  <van-popup
      v-model:show="mobilePopupShow"
      position="bottom"
      :style="{ height: '40%' }"
  >
    <div class="content">
      <div class="welcome">
        <div>欢迎来到思维独角兽</div>
        <div>我们一起来学英语吧</div>
      </div>
      <div class="main">
        <div class="loginName">账号登录</div>
        <div class="main_form">
          <van-form @submit="onSubmit">
            <van-cell-group inset>
              <!--使用 Field 的 rules 属性定义校验规则。通过 validator 进行函数校验-->
              <van-field
                  v-model="phone"
                  name="请输入手机号"
                  placeholder="请输入手机号"
                  :rules="[{ required: true, message: '请输入手机号' }, { validator: validateTel, message: '手机号输入不合法' }]"
              />
              <van-field
                  v-model="password"
                  name="请输入8-16位数字和字母组合的密码"
                  placeholder="请输入8-16位数字和字母组合的密码"
                  :rules="[{ required: true, message: '请输入密码' }, { validator: validatePassword, message: '请输入8-16位数字和字母组合的密码'}]"
                  :type="isShowed? 'text' : 'password' "
                  :right-icon="isShowed ? 'eye-o' : 'closed-eye'"
                  @click-right-icon="changeRightIcon"
              />
            </van-cell-group>
            <van-button block type="primary" native-type="submit" class="formButton" color="#16a37f">
              登录
            </van-button>
          </van-form>
          <div class="row-between toPasswordRegister">
            <div class="pointerCursor" @click="$router.push('/forgetPassword')">
              忘记密码？
            </div>
            <div class="pointerCursor" @click="$router.push('/register')">
              新用户账号注册
            </div>
          </div>
        </div>
      </div>
    </div>
  </van-popup>

  <van-tabbar v-model="active">
    <van-tabbar-item icon="service-o" @click="showActionSheet = true">听力</van-tabbar-item>
    <van-tabbar-item icon="search" @click="openInNewTab('http://www.wph0.cn')">资讯</van-tabbar-item>
    <van-tabbar-item icon="fire-o" @click="openInNewTab('http://www.wph1.cn')">AI</van-tabbar-item>
    <van-tabbar-item icon="friends-o" @click="openInNewTab('http://www.wph6.cn')">起名</van-tabbar-item>
    <van-tabbar-item icon="home-o" @click="changeTabItem('/home')">我的</van-tabbar-item>
  </van-tabbar>
  <van-action-sheet v-model:show="showActionSheet" :actions="actionsActionSheet" @select="onSelectActionSheet" />
</template>

<script>
import {onMounted, reactive, ref, toRefs} from 'vue';
import { useRouter } from 'vue-router'
import store from "@/store/store";
import { showToast } from 'vant';
import {useRoute} from "vue-router";
import {mapState} from "vuex";
export default {
  name: 'AppLayout',
  setup() {
    const isShowed = ref(false)
    // 添加路由监听
    const router = useRouter();
    const routerViewKey = ref(0);
    const showActionSheet = ref(false);
    const actionsActionSheet = [
      { name: '完形填空' },
      { name: '按句精听' },
    ];
    const onSelectActionSheet = (item) => {
      // 默认情况下点击选项时不会自动收起
      // 可以通过 close-on-click-action 属性开启自动收起
      showActionSheet.value = false;
      router.push('/')
      //router.push(url)
      //showToast(item.name);

      const numberRegexList = /^\/list\/\d+\/$/;
      const currentSrtName = store.state.srtType == 'srtCloze' ? '完形填空' : '按句精听'
      //if(!(currentSrtName == item.name)){
        if (item.name === '按句精听') {
          store.state.srtType='srtList'
          routerViewKey.value++;
          //console.log("AppLayout:routerViewKey.value", routerViewKey.value)
        } else {
          store.state.srtType='srtCloze'
          routerViewKey.value++;
          //console.log("AppLayout:routerViewKey.value", routerViewKey.value)
        }
      //}
    };
    const password = ref('');
    const validatePassword = (val) => /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,16}$/.test(val)
    const phone = ref('');
    const validateTel = (val) => /^[0-9]{11}$/.test(val)
    const mobilePopupShow = ref(false);
    const active = ref(0);
    onMounted(() => {
      const currentPath = router.currentRoute.value.path;
      console.log("currentPath", currentPath)
      if (currentPath === '/home') {
        active.value = 4; // “我的”对应的索引为 4
      }
    });
    const openInNewTab = (url) => {
      window.open(url, '_blank');
      active.value = 0;
    };
    const changeTabItem = (url) => {
      const isLogin = store.getters.isLogin == 0 ? false : true;
      //const isLogin = true
      if(isLogin){
        router.push(url)
      }else{
        mobilePopupShow.value = true;
      }
    }
    const methods = reactive({
      changeRightIcon () {
        isShowed.value = !isShowed.value
      }
    })
    const onSubmit = (values) => {
      //用reactive声明Login中的userInfo.username从object变成了string
      const form = reactive({
        username: phone,
        password: password
      })
      try {
        store.dispatch('Login', form).then(() => {
          store.dispatch('GetInfo').then(() => {
            router.push('/home')
            mobilePopupShow.value = false
          }).catch((error) => {
            console.log(error)
          })
        }).catch((error) => {
          console.log(error)
        })
      //不加这个catch，Login中的错误userInfo.username.trim()就显示不出来
      } catch (e) {
        console.log(e)
      }
    }

    return {
      ...toRefs(methods),
      isShowed,
      routerViewKey,
      showActionSheet,
      actionsActionSheet,
      onSelectActionSheet,
      onSubmit,
      password,
      validatePassword,
      phone,
      validateTel,
      mobilePopupShow,
      active,
      openInNewTab,
      changeTabItem
    };
  },
  /*computed: {
    ...mapState(["srtType"]),
  },
  watch:{
    srtType(newVal){
      store.state.srtType=newVal
      //this.routerViewKey.value++;
      console.log("AppLayout.srtType", store.state.srtType)
    }
  }*/
  /*,
  created() {
    console.log('当前访问路径：', this.$route.path);
  },*/

};
</script>

<style scoped>
header {
  background-color: #333;
  color: white;
  padding: 10px;
}
nav {
  display: flex;
  justify-content: space-between;
}
nav a {
  color: white;
  text-decoration: none;
  padding: 5px 10px;
}
nav a:hover {
  background-color: #555;
}
</style>
