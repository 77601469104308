import Vuex from 'vuex';
import { getUserStatus, getInfo, login } from '@/api/login'
import { getHistoryVideo } from '@/api/resource'
import defAva from '@/assets/profile.jpg'
import { setToken, removeToken, getToken, setExpiresIn } from '@/utils/auth'
import { phoneCodeForgetPassword, getCaptcha } from '@/api/forgetPassword'
import { phoneCodeRegister } from '@/api/register'
import { showNotify } from "vant";
import {useRoute} from "vue-router";

const store = new Vuex.Store({
    state: {
        currentItemId: 1,
        currentItemStartTime: 0,
        currentItemEndTime: 0,
        isLogin: 0,
        userInfo: [],
        avatar: '',
        name: '',
        roles: '',
        expireTime: '',
        residualDegree: '',
        checkIn: 0,
        token: getToken(),
        permissions: [],
        isMobile: false,
        srtType: 'srtCloze',
        srtAndVideoList: [],
        currentPlayerIds: [],
        currentTranscript: [],
        currentItemCurrentTime: 0,
    },
    mutations: {
        setCurrentItemCurrentTime(state, currentItemCurrentTime) {
            state.currentItemCurrentTime = currentItemCurrentTime;
        },
        setCurrentTranscript(state, currentTranscript) {
            state.currentTranscript = currentTranscript;
        },
        setCurrentItemId(state, id) {
            state.currentItemId = id;
        },
        setCurrentItemStartTime(state, startTime) {
            state.currentItemStartTime = startTime;
        },
        setCurrentItemEndTime(state, endTime) {
            state.currentItemEndTime = endTime;
        },
        setIsLogin: (state, isLogin) => {
            state.isLogin = isLogin
        },
        setUserInfo: (state, userInfo) => {
            state.userInfo = userInfo
        },
        setAvatar: (state, avatar) => {
            state.avatar = avatar
        },
        setName: (state, name) => {
            state.name = name
        },
        setRoles: (state, roles) => {
            state.roles = roles
        },
        setExpireTime: (state, expireTime) => {
            state.expireTime = expireTime
        },
        setResidualDegree: (state, residualDegree) => {
            state.residualDegree = residualDegree
        },
        setCheckIn: (state, checkIn) => {
            state.checkIn = checkIn
        },
        setToken: (state, token) => {
            state.token = token
        },
        setPermissions: (state, permissions) => {
            state.permissions = permissions
        },
        setExpiresIn: (state, time) => {
            state.expiresIn = time
        },
        setModel: (state, id) => {
            state.isMobile = id
        },
        setSrtType(state, srtType) {
            state.srtType = srtType;
        },
        setSrtAndVideoList(state, srtAndVideoList) {
            state.srtAndVideoList = srtAndVideoList;
        },
        setCurrentPlayerIds(state, currentPlayerId) {
            state.currentPlayerIds.push(currentPlayerId);
        },
    },
    actions: {
        SrtType({ commit }, srtType) {
            commit('setSrtType', srtType)
        },
        SrtAndVideo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getHistoryVideo().then(res => {
                    const code = res.data.code
                    const data = res.data.data
                    //console.log(code, data)
                    if (res.status == 200){
                        if (code == 0 && (data != undefined)){
                            commit('setSrtAndVideoList', data)
                        }
                        //console.log("srtAndVideoList", state.srtAndVideoList)
                    }
                    resolve(res)
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })
            })
        },
        // eslint-disable-next-line no-unused-vars
        GetIsLoginAndToken({ commit, state }) {
            return new Promise((resolve, reject) => {
                getUserStatus().then(res => {
                    const data = res.data.data
                    const id = data.id
                    const adminToken = data.adminToken
                    const adminExpiresIn = data.adminExpiresIn
                    const tokenType = data.tokenType
                    const isLogin = data.isLogin
                    const isFirstVisit = data.isFirstVisit
                    const isSharedUser = data.isSharedUser
                    commit('setIsLogin', isLogin)//添加了这行代码之后，本来isLogin默认值是1但是第一次访问网站没登录所以自动更新成了0
                    if(adminToken != undefined){
                        setToken(adminToken)//Cookies.set("Admin-Token", adminToken, { expires: 7 })
                    }
                    resolve(res)
                }).catch(error => {
                    console.log(error)
                    reject(error)
                })
            })
        },
        GetInfo({ commit, state }) {
            return new Promise((resolve, reject) => {
                getInfo().then(res => {
                    const userInfo = res.data.data

                    //console.log("userInfo.id", userInfo.id)//3293970316472090624
                    //console.log("userInfo.nickname", userInfo.nickname)//16472090624
                    //console.log("userInfo.phone", userInfo.phone)//13900000000
                    //console.log("userInfo.level", userInfo.level)//用户种类，免费0
                    //console.log("userInfo.residualDegree", userInfo.residualDegree)//剩余条数，2
                    //console.log("userInfo.lastDay", userInfo.lastDay)//不需要，false
                    //console.log("userInfo.expiretime", userInfo.expiretime)//付费用户的过期时间，null
                    //console.log("userInfo.createtime", userInfo.createtime)//用户创建时间，2024-11-19 15:02:02
                    //console.log("userInfo.updatetime", userInfo.updatetime)//不需要，null
                    //console.log("userInfo.checkIn", userInfo.checkIn)//签到，0

                    commit('setUserInfo', userInfo)
                    const avatar = this.avatar == '' ? defAva : this.avatar
                    commit('setAvatar', avatar)
                    commit('setName', userInfo.nickname)
                    commit('setRoles',userInfo.level)
                    commit('setExpireTime', userInfo.expiretime)
                    commit('setResidualDegree', userInfo.residualDegree)
                    commit('setCheckIn', userInfo.checkIn)
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        LogOut ({ commit, state }) {
            return new Promise((resolve, reject) => {
                commit('setToken', '')
                commit('setRoles', [])
                commit('setPermissions', [])
                removeToken()
                resolve()
            })
        },
        Login ({ commit }, userInfo) {
            const username = userInfo.username.trim()
            const password = userInfo.password
            return new Promise((resolve, reject) => {
                login(username, password).then(res => {
                    if (res.status == 200){
                        if (res.data.code == 0){
                            const data = res.data.data
                            //console.log("logindata", data)//Object { access_token: "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2luZm9fanNvbiI6IjMwNzQwMjkzNTA4MjQxODE3NjAiLCJleHAiOjE3MzMyMDQxMTB9.1Ks28dLIMTgf6HywB6n0CrMZio4CHRPStDcJyhc7HdyLIUe0pcnqsAkol7gxSqZ4tbbAY2qjZACmqFSyHS6E7w", expires_in: "", refresh_token: "eyJhbGciOiJIUzUxMiJ9.eyJ1c2VyX2luZm9fanNvbiI6IjMwNzQwMjkzNTA4MjQxODE3NjAiLCJleHAiOjE3MzM4MDg5MTB9.pkNgQ-OfpCZF9rTWZ2WFaqSILJ7l7Rp0QczWmC9SeSCNahp1_0OAs0UgmrDXgtHNKaZ0G1B330m9_T856YxjjA", scope: "all", token_type: "jwt", userId: "3074029350824181760" }
                            setToken(data.access_token)
                            commit('setToken', data.access_token)
                            setExpiresIn(data.expires_in)
                            commit('setExpiresIn', data.expires_in)
                        } else if (res.data.code != 0){
                            showNotify({ type: 'warning', message: res.data.message })
                        }
                        resolve()
                    }else{
                        showNotify({ type: 'warning', message: "响应非200" })
                    }
                }).catch(error => {
                    showNotify({ type: 'warning', message: error })
                    console.log(error)
                    reject(error)
                })
            })
        },
        getPhoneImg ({ commit }, phone) {
            return new Promise((resolve, reject) => {
                getCaptcha(phone).then((res) => {
                    resolve(res)
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GainRegisterPhoneCode ({ commit }, data) {
            return new Promise((resolve, reject) => {
                phoneCodeRegister(data).then(() => {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        GainForgetPhoneCode ({ commit }, data) {
            return new Promise((resolve, reject) => {
                phoneCodeForgetPassword(data).then(() => {
                    resolve()
                }).catch(error => {
                    reject(error)
                })
            })
        },
        Model ({ commit }, id) {
            commit('setModel', id)
        }
    },
    getters:{
        isLogin: state => state.isLogin,
        userInfo: state => state.userInfo,
        avatar: state => state.avatar,
        name: state => state.name,
        roles: state => state.roles,
        expireTime: state => state.expireTime,
        residualDegree: state => state.residualDegree,
        checkIn: state => state.checkIn,
        token: state => state.token,
        isModel: state => state.isMobile,
        srtType: state => state.srtType,
        srtAndVideoList: state => state.srtAndVideoList,
        currentPlayerIds: state => state.currentPlayerIds,
        currentTranscript: state => state.currentTranscript,
        currentItemCurrentTime: state => state.currentItemCurrentTime
    }
});

export default store;
