<template>
  <van-list
      v-model:loading="loading"
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
  >
    <!--在 Vue 3 中，当在模板中使用 v-for 遍历 transcript 数组并将 item 作为 van-cell 的 title 属性值时，如果显示为 Object object，是因为直接将一个对象作为字符串输出时，JavaScript 会默认调用对象的 toString() 方法，而这个方法通常返回 [object Object]。
    使用 item.id 作为 v-for 的 key 属性值以确保每个循环项有唯一的标识。同时，将 item.text 数组通过 join(' ') 方法转换为字符串后作为 van-cell 的 title 属性值，这样就可以显示 text 属性的内容了。-->
    <van-cell v-for="item in transcript" :key="item.id" center size="large" :title-class="titleClass(item.id)" :ref="setCellRef">
      <template #icon>
        <van-icon name="play-circle" size="20px" @click="handleItemClick(item, $event)" color="#438EDB"/>
      </template>
      <template #title>
        <!--highlighted：是类名。highlightedWord === word：是一个条件表达式。
        当 highlightedWord 的值等于 word 的值时，该条件表达式的结果为 true，此时元素会被添加 highlighted 类；当条件表达式的结果为 false 时，元素不会添加 highlighted 类。-->
        <!--<div :class="isTitleRed? 'red-text' : ''" :key="item.id">-->

        <span v-for="(word, index) in item.text" :key="word" @click="changeWordColor(word)" :class="{ highlighted: highlightedWord === word }">
          {{ index > 0? ' ' : '' }}{{ word }}
        </span>
      </template>
    </van-cell>
  </van-list>
</template>

<script>
import { showToast } from 'vant';
import {mapMutations, mapGetters, mapState } from 'vuex';
import { ref, watchEffect, toRefs, computed, watch } from 'vue';
import { showDialog } from 'vant';
import store from "@/store/store";
import VideoPlayer from "@/components/VideoPlayer.vue";

function timeStringToSeconds(timeString) {
  const parts = timeString.split(':');//parseInt函数在解析字符串时，会从字符串的开头开始解析，直到遇到一个不能转换为数字的字符为止
  let seconds = 0;
  for (let i = parts.length - 1; i >= 0; i--) {
    seconds += parseInt(parts[i]) * Math.pow(60, parts.length - 1 - i);
  }
  const outerParts = timeString.split(',');
  const milliseconds = parseInt(outerParts[1]) / 1000
  seconds += milliseconds
  return seconds;
}

function playAudio(url) {
  const audio = new Audio(url);
  audio.play();
}

export default {
  props: {
    options: {
      type: String, // 根据实际值的类型定义
      default: '',
    }
  },
  data() {
    return {
      contentLines: [],
      transcript: [],
      highlightedWord: null,
      isTitleRed: false
    };
  },
  setup(props) {
    /*
    在 van-cell 元素上添加 ref 属性，使用函数式 ref 来将元素添加到一个数组中。
    在 setup 函数中创建一个 refs 数组。
    在 watch 中根据 item 的数据变化来操作 refs 数组中对应的元素。*/
    const cellRefs = ref([]);
    const setCellRef = (el) => {
      if (el) {
        //console.log(el)
        cellRefs.value.push(el);
      }
    };

    const list = ref([]);
    const loading = ref(false);
    const finished = ref(false);
    const onLoad = () => {
      // 异步更新数据
      // setTimeout 仅做示例，真实场景中一般为 ajax 请求
      setTimeout(() => {
        for (let i = 0; i < 10; i++) {
          list.value.push(list.value.length + 1);
        }

        // 加载状态结束
        loading.value = false;

        // 数据全部加载完成
        if (list.value.length >= 40) {
          finished.value = true;
        }
      }, 1000);
    };

    //const onChange = (index) => showToast('当前 Swipe 索引：' + index);
    /*在 Vue 3 中，使用 setup() 函数时，mapGetters 的使用方式有所不同，不能像 Vue 2 那样直接在 methods 中使用 ...mapGetters 展开。
    * 修改方案：
    在 setup() 函数中使用 computed 来引入 getters，而不是在 methods 中使用 ...mapGetters。
    使用 toRefs 将 getters 转为响应式引用，以便在 setup() 函数中使用。
    * 在 setup() 函数中：
    const { currentItemCurrentTime } = toRefs(store.getters);：使用 toRefs 将 store.getters 中的 currentItemCurrentTime 转换为响应式引用，以便在 setup 中使用。
    const computedCurrentItemCurrentTime = computed(() => currentItemCurrentTime.value);：使用 computed 函数创建一个计算属性，该计算属性的值会根据 currentItemCurrentTime 的值进行计算。
    watch(computedCurrentItemCurrentTime, (newVal, oldVal) => {...})：使用 watch 函数来监听 computedCurrentItemCurrentTime 的变化，当它发生变化时，会执行相应的回调函数。*/
    /*const { currentItemCurrentTime } = toRefs(store.getters);
    const computedCurrentItemCurrentTime = computed(() => currentItemCurrentTime.value);
    watch(computedCurrentItemCurrentTime, (newVal, oldVal) => {
      console.log('currentItemCurrentTime 发生了变化：', newVal);
    });*/
    return {
      list,
      onLoad,
      loading,
      finished,
      //computedCurrentItemCurrentTime,
      setCellRef,
      cellRefs
    };
  },
  methods: {
    titleClass(id) {
      return { ['itemId_' + id]: true };
    },
    changeWordColor(word) {
      if (this.highlightedWord === word) {
        this.highlightedWord = null;
      } else {
        this.highlightedWord = word;
        // 调用接口获取翻译并显示在弹框中
        fetch(`https://api.dictionaryapi.dev/api/v2/entries/en/${word}`)
            .then(response => response.json())
            .then(data => {
              if (data && data.length > 0 && data[0].meanings && data[0].meanings.length > 0 && data[0].meanings[0].definitions && data[0].meanings[0].definitions.length > 0 && data[0].phonetics && data[0].phonetics.length > 0) {
                const phonetic = data[0].phonetic? data[0].phonetic : '';
                const partOfSpeech = data[0].meanings[0].partOfSpeech? data[0].meanings[0].partOfSpeech : '';
                const definition = data[0].meanings[0].definitions[0].definition? data[0].meanings[0].definitions[0].definition : '';
                const audioUrl = data[0].phonetics[0].audio? data[0].phonetics[0].audio : '';
                //const message = `${word} /${phonetic}/ <button onclick="playAudio('${audioUrl}')">按钮</button>\n${partOfSpeech}\n${definition}`;
                const message = `${word} ${phonetic} \n${partOfSpeech}\n${definition}`;
                showDialog({
                  message: message,
                  messageAlign: "left"
                }).then(() => {
                  // on close
                  this.highlightedWord = null;
                });
              } else {
                showDialog({
                  message: `未找到 ${word} 的翻译`,
                  messageAlign: "left"
                }).then(() => {
                  // on close
                  this.highlightedWord = null;
                });
              }
            })
            .catch(error => {
              showDialog({
                message: `获取 ${word} 翻译时出错：${error}`,
                messageAlign: "left"
              }).then(() => {
                // on close
              });
            });
      }
    },
    getSplitText(sentence) {
      const words = sentence.split(' ');
      return words
    },
    ...mapMutations(['setCurrentItemId', "setCurrentItemStartTime", "setCurrentItemEndTime", "setCurrentTranscript"]),
    //...mapState(['currentItemCurrentTime']),
    //...mapGetters(['currentItemCurrentTime']),
    /*在 handleItemClick 方法定义处接收这个事件对象，并通过它获取到目标元素
    * 这里使用了 closest 方法（在现代浏览器中广泛支持），它会从当前触发事件的元素开始，沿着 DOM 树向上查找，直到找到匹配指定选择器（这里是 van-cell）的第一个祖先元素。*/
    handleItemClick(item, event) {
      //const clickedDiv = event.target.parentElement.children[1]
      this.setCurrentItemStartTime(item.startTime)
      this.setCurrentItemEndTime(item.endTime)
      //console.log("currentItemStartTime", item.startTime)
      //console.log("currentItemCurrentTime", this.computedCurrentItemCurrentTime.value)
      //console.log("currentItemStartTime", item.endTime)
      //clickedDiv.style.color = "red"
      //console.log("VideoPlayer.data.player", VideoPlayer)
      //this.isTitleRed = true;
    },
    getWindowHeight(){},
    async getTxtContentUsingFetch(options) {
      try {
        const response = await fetch(options.src);
        if (response.ok) {
          const content = await response.text();
          this.contentLines = content.split('\n');
          for (let i = 0; i < this.contentLines.length; i += 4) {
            if (this.contentLines[i] && this.contentLines[i + 1] && this.contentLines[i + 2]) {
              if (this.getSplitText(this.contentLines[i + 2]).length < 9 && i <= (this.contentLines.length - 8)) {
                //console.log("text: ", this.getSplitText(this.contentLines[i + 2]).concat(this.getSplitText(this.contentLines[i + 6])))
                this.transcript.push({
                  id: parseInt(this.contentLines[i]),
                  timeRange: this.contentLines[i + 1].split(' --> ')[0] + ' --> ' + this.contentLines[i + 5].split(' --> ')[1],
                  text: this.getSplitText(this.contentLines[i + 2].trim()).concat(this.getSplitText(this.contentLines[i + 6].trim())),
                  //randomIndexes: this.getRandomIndexes(this.contentLines[i + 2]),
                  startTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[0]),
                  endTime: timeStringToSeconds(this.contentLines[i + 5].split(' --> ')[1]),
                });
                i+=4
                //console.log("i: ", i)
              }else{
                //console.log("text: ", this.getSplitText(this.contentLines[i + 2]))
                this.transcript.push({
                  id: parseInt(this.contentLines[i]),
                  timeRange: this.contentLines[i + 1],
                  text: this.getSplitText(this.contentLines[i + 2].trim()),
                  //randomIndexes: this.getRandomIndexes(this.contentLines[i + 2]),
                  startTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[0]),
                  endTime: timeStringToSeconds(this.contentLines[i + 1].split(' --> ')[1]),
                });
                //console.log("i: ", i)
              }
            }
          }
          //设置初始值
          if (this.transcript.length > 0) {
            this.setCurrentItemId(this.transcript[0].id);
            this.setCurrentItemStartTime(this.transcript[0].startTime);
            this.setCurrentItemEndTime(this.transcript[0].endTime);
            //console.log("SrtList:this.contentLines[1].split(' --> ')[0])", timeStringToSeconds(this.contentLines[1].split(' --> ')[0]))
          }
          this.setCurrentTranscript(this.transcript)
        } else {
          console.error('无法获取 txt 文件内容');
        }
      } catch (error) {
        console.error('发生错误：', error);
      }
    }
  },
  mounted() {
    this.getWindowHeight();
    this.getTxtContentUsingFetch(this.options);
  },
  onMounted(){

  },
  computed: {
    ...mapState(['currentItemCurrentTime'])
  },
  watch: {
    currentItemCurrentTime(newVal) {
      this.transcript.forEach((item, index) => {
        const startTime = item.startTime;
        const endTime = item.endTime;
        if (newVal >= startTime && newVal <= endTime) {
          this.cellRefs[index].$el.children[1].style.color="red"
        }else{
          this.cellRefs[index].$el.children[1].style.color="#323233";
        }
      });
    }
  },
  name: "SrtList"
}
</script>

<style scoped>
.itemId_0{
  color: #2196f3;
}
/*.van-cell{
  color: #2196f3;
}*/
.highlighted {
  /*color: #2c3e50;*/
  color: green;
}
.red-text {
  color: red;
}
.clickedDivStyle {
  color: red;
  font-weight: bold;
}

</style>