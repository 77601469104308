<template>
  <van-nav-bar
      title="历史视频"
      left-text="返回"
      left-arrow
      @click-left="onClickLeft"
  />
  <van-list
      :finished="finished"
      finished-text="没有更多了"
      @load="onLoad"
  >
    <van-cell v-for="item in currentPageItems" :title="item.title" :name="item.id" :key="item.id" center @click="handleItemClick(item.id)"></van-cell>
  </van-list>
  <van-pagination
      v-model="currentPage"
      :total-items="tabList.length"
      :show-page-size="3"
      :items-per-page="itemsPerPage"
      force-ellipses
      @change="onPageChange"
  />
</template>

<script>
// eslint-disable-next-line no-unused-vars
import {ref, onMounted, reactive, nextTick, computed} from 'vue';
import store from "@/store/store";
import {useRouter} from "vue-router";

export default {
  name: 'VideoList',
  //在 Vue 3 的setup函数中，没有this上下文。这是因为setup函数是在组件实例创建之前被调用的，所以无法访问组件实例的属性和方法。
  setup() {
    const router = useRouter();
    const handleItemClick = (id) => {
      router.push(`/${id}/`)
      //console.log(id)
    };
    const itemsPerPage = 10;
    const onClickLeft = () => history.back();
    const currentPage = ref(1);
    /*const tabList = ref([
      { title: '20241208', id: '0', video:'/NBC_Nightly_News_20241208.mp4', srt:'/NBC_Nightly_News_20241208.srt' },
      { title: '20241207', id: '1', video:'/NBC_Nightly_News_20241207.mp4', srt:'/NBC_Nightly_News_20241207.srt' },
      { title: '20241206', id: '2', video:'/NBC_Nightly_News_20241206.mp4', srt:'/NBC_Nightly_News_20241206.srt' },
      { title: '20241205', id: '3', video:'/NBC_Nightly_News_20241205.mp4', srt:'/NBC_Nightly_News_20241205.srt' },
      { title: '20241204', id: '4', video:'/NBC_Nightly_News_20241204.mp4', srt:'/NBC_Nightly_News_20241204.srt' },
      // 更多标签页...
    ]);*/
    const srtAndVideoList = store.getters.srtAndVideoList
    const tabList = ref(srtAndVideoList)
    const currentPageItems = computed(() => {
      const startIndex = (currentPage.value - 1) * itemsPerPage;
      return tabList.value.slice(startIndex, startIndex + itemsPerPage);
    });
    return {
      handleItemClick,
      itemsPerPage,
      tabList,
      onClickLeft,
      currentPage,
      currentPageItems,
    };
  },
  mounted() {
  },
};
</script>
