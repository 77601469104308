<template>
  <!--<p>
    <strong>Current route path:</strong> {{ $route.fullPath }}
  </p>
  <nav>
    <RouterLink to="/home">Go to Home</RouterLink>
    <RouterLink to="/about">Go to About</RouterLink>
  </nav>-->
  <main>
    <RouterView />
  </main>
  <!--<TestComponent msg="This comes from testComponent"/>-->
  <!--<img alt="Vue logo" src="./assets/logo.png">-->
  <!--<HelloWorld msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
//import TestComponent from './components/TestComponent.vue'
import { defineComponent, onMounted } from 'vue'
import {_isMobile} from '@/utils/common'
import store from "@/store/store";
import {showNotify} from "vant";
/*export default {
  name: 'App',
  components: {
    //HelloWorld,
    //TestComponent
  },
}*/

export default defineComponent({
  name: 'App',
  setup() {
    onMounted(() => {
      if (_isMobile()) {
        //console.log("App.vue::_isMobile::", true)
        store.dispatch('Model', true)//原来的'MODEL'方法
      } else {
        //console.log("App.vue::_isMobile::", false)
        store.dispatch('Model', false)
      }
      //console.log("App.vue::store.getters.isModel::", store.getters.isModel)//一刷新http://localhost:2021/#/landing，就会显示false
      store.dispatch('SrtAndVideo').then(() => {
      }).catch(err => {
        console.log(err)
      })
    })
  },
  components: {
    //HelloWorld,
    //TestComponent
  },
})

</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100vh; /* 设置高度为视口高度的 100% */
  background: linear-gradient(to bottom, #ffffff, #e6f0ff); /* 从白色到淡蓝色的渐变 */
}
</style>
