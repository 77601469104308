import router from "@/router/router";
import store from './store/store.js';
import { showNotify } from 'vant'
import {mapMutations} from "vuex";

const whiteList = ['/', '/forgetPassword', '/register', '/login', '/more']
// 正则表达式，匹配任意数字路径
//const numberRegex = /^\/\d+$/;
const numberRegex = /^\/\d+\/$/;
const numberRegexList = /^\/list\/\d+\/$/;
// eslint-disable-next-line no-unused-vars
router.beforeEach((to, from, next) => {
    // eslint-disable-next-line no-unused-vars
    store.dispatch('GetIsLoginAndToken').then(res => {
        const isLogin = store.getters.isLogin == 0 ? false : true;
        store.dispatch('GetInfo').then(() => {
            //console.log("permission.GetInfo执行")
            if(isLogin) {
                next()
            } else if (whiteList.indexOf(to.path) !== -1) {
                next()
            } else if (numberRegex.test(to.path)){
                next()
            } else if (numberRegexList.test(to.path)){
                next()
            }else {
                next(`/?redirect=${to.fullPath}`)//如果isLogin是false，这时点击/home，这个页面并不在白名单中，这时会跳到/，且浏览器显示http://127.0.0.1:2022/?redirect=/home
            }
        }).catch(err => {
            store.dispatch('LogOut').then(() => {
                showNotify({ type: 'warning', message: err })
                console.log("permission.err", err)
                next({ path: '/' })
            })
        })
    })
})