<template>
  <div class="container">
    <!--在标签指定 name 属性的情况下，v-model:active 的值为当前标签的 name（此时无法通过索引值来匹配标签）。-->
    <van-tabs v-model:active="active" @click-tab="onClickTab" sticky offset-top="2px">
      <van-tab v-for="item in tabList" :title="item.title" :name="item.id" :key="item.id">
        <!-- 这里是对应标签页的内容 -->
        <!--<div v-for="content in item.content" :key="content">{{ content }}</div>-->
        <!--字幕和视频还不能加active，要不然点第二个tab就触发两次，第三个tab就触发三次-->
        <!--<div class="srt" :key="srtId">-->
        <div class="srt">
          <div v-if="srtType === 'srtCloze'">
            <SrtCloze :options="srtOptions" />
          </div>
          <div v-else-if="srtType === 'srtList'">
            <SrtList :options="srtOptions" />
          </div>
        </div>
        <!--<video-player :options="videoOptions" :startTime="startTime" :endTime="endTime" :key="playerId"/>-->
        <video-player :options="videoOptions" :startTime="startTime" :endTime="endTime" ref="videoPlayerComponent"/>
        <!--<van-sticky :offset-bottom="100" position="bottom">
          <video-player :options="videoOptions" :startTime="startTime" :endTime="endTime"/>
        </van-sticky>-->
      </van-tab>
      <template #nav-right>
        <div class="fixed-right-button">
          <van-button round type="primary" size="small" @click="$router.push('/more')">
            More
            <!--<template #icon>
              <van-icon name="ellipsis" />
            </template>-->
          </van-button>
        </div>
      </template>
    </van-tabs>
  </div>

</template>

<script>
// eslint-disable-next-line no-unused-vars
import SrtCloze from '@/components/SrtCloze.vue';
import SrtList from '@/components/SrtList.vue';
import VideoPlayer from '@/components/VideoPlayer.vue';
import {mapMutations, mapState} from 'vuex';
// eslint-disable-next-line no-unused-vars
import { ref, onMounted, reactive, nextTick, toRefs } from 'vue';
import store from "@/store/store";
import {useRoute, useRouter} from "vue-router";

export default {
  name: 'TestComponent',
  //在 Vue 3 的setup函数中，没有this上下文。这是因为setup函数是在组件实例创建之前被调用的，所以无法访问组件实例的属性和方法。
  setup(props) {
    const videoPlayerComponent = ref(null);
    const getCurrentTime = () => {
      //const player = videoPlayerComponent.value.getPlayer();
      /*if (player) {
        console.log(player.currentTime());
      } else {
        console.log('Player not initialized yet.');
      }*/
    };

    const srtId = ref(0);
    const playerId = ref(0);
    //const playerKey = ref(0);
    /*const tabList = ref([
      { title: '20241208', id: '0', video:'/NBC_Nightly_News_20241208.mp4', srt:'/NBC_Nightly_News_20241208.srt' },
      { title: '20241207', id: '1', video:'/NBC_Nightly_News_20241207.mp4', srt:'/NBC_Nightly_News_20241207.srt' },
      { title: '20241206', id: '2', video:'/NBC_Nightly_News_20241206.mp4', srt:'/NBC_Nightly_News_20241206.srt' },
      { title: '20241205', id: '3', video:'/NBC_Nightly_News_20241205.mp4', srt:'/NBC_Nightly_News_20241205.srt' },
      { title: '20241204', id: '4', video:'/NBC_Nightly_News_20241204.mp4', srt:'/NBC_Nightly_News_20241204.srt' },
      // 更多标签页...
    ]);*/
    const router = useRouter();
    const startTime = ref(0)
    const endTime = ref(0)
    const active = ref(0);
    const numberRegexList = /^\/list\/\d+\/$/;
    const srtType = store.getters.srtType
    //console.log("srtType", srtType)
    /*if (numberRegexList.test(useRoute().path)) {
      store.dispatch("SrtType", "srtList")
    }*/

    //const srtType = this.setSrtType("srtList")
    const srtAndVideoList = store.getters.srtAndVideoList
    const tabList = ref(srtAndVideoList)
    //console.log("srtAndVideoList", srtAndVideoList)
    //console.log("tabList", tabList)

    // 使用 reactive 创建响应式对象
    const videoOptions = reactive({
      autoplay: true,
      controls: true,
      sources: [
        {
          src: '',
          type: 'video/mp4'
        }
      ]
    });
    const srtOptions = reactive({
      src: ''
    });
    const onClickTab = () => {
      const id = active.value;
      //console.log("id", id)
      var activeItem = tabList.value[id];
      var srtSrc = activeItem.srt;
      var videoSrc = activeItem.video;
      videoOptions.sources[0].src = videoSrc;
      srtOptions.src = srtSrc;
      //playerKey.value++
      //得加这句，要不然按tab到第二个点击按句精听会跳转成第一个
      //router.push(`/${id}`)
      //srtId.value++
      //playerId.value++
      //不能用这个，一个是因为如果使用这个点击了第二个tab，然后选择srtList，再点击第一个tab会变回srtCloze；二是因为打开页面特别慢
      window.location.href = `/${id}/`;
      /*if (srtType == "srtCloze"){
        window.location.href = `/${id}/`;
      }else{
        window.location.href = `/list/${id}/`;
        this.setSrtType("srtList")
      }*/
      //console.log("activeItem", activeItem)
      //console.log("srtSrc", srtSrc)
      //console.log("videoSrc", videoSrc)
    };

    function assignValue(anyNumberValue) {
      active.value = anyNumberValue
      const id = active.value;
      var activeItem = tabList.value[id];
      var srtSrc = activeItem.srt;
      var videoSrc = activeItem.video;
      videoOptions.sources[0].src = videoSrc;
      srtOptions.src = srtSrc;
    }

    onMounted(() => {
      const numberRegexList = /^\/list\/\d+\/$/;
      const routeParams = useRoute().params;//Object { anyNumber: "0" }
      const anyNumberValue = routeParams.anyNumber
      //const anyNumberRef = toRefs(routeParams.anyNumber);
      //const anyNumberValue = anyNumberRef[0]?.value
      //只有访问/n/或/list/n/才会有anyNumber，这个是在router.js中控制的
      if (anyNumberValue && tabList.value.some(item => item.id == anyNumberValue)) {
        assignValue(anyNumberValue);
      } else {
        assignValue(0)
      }
      /*if (numberRegexList.test(useRoute().path)){
        store.dispatch("SrtType", "srtList")
        console.log(store.getters.srtType)
      }else{
        store.dispatch("SrtType", "srtCloze")
        console.log(store.getters.srtType)
      }*/

      /*if (anyNumberValue) {
        //console.log("anyNumberRef", anyNumberValue)
        active.value = anyNumberValue
        const id = active.value;
        //const ids = this.tabList.map(item => item.id);
        //tabList.some(item => item.id === anyNumberValue.toString());
        if (tabList.value.some(item => item.id == id)){
          var activeItem = tabList.value[id];
          var srtSrc = activeItem.srt;
          var videoSrc = activeItem.video;
          videoOptions.sources[0].src = videoSrc;
          srtOptions.src = srtSrc;
        }else{
          //router.push("/0")
          active.value = 0
          onClickTab()
        }
      } else {
        //console.log("执行onClickTab()")
        onClickTab()
      }*/
    });

    return {
      videoPlayerComponent,
      getCurrentTime,
      playerId,
      srtId,
      //playerKey,
      srtType,
      active,
      onClickTab,
      videoOptions,
      srtOptions,
      tabList,
      startTime,
      endTime
    };
  },
  components: {
    SrtList,
    // eslint-disable-next-line vue/no-unused-components
    SrtCloze,
    // eslint-disable-next-line vue/no-unused-components
    VideoPlayer,
  },
  computed: {
    ...mapState(['currentItemId', "currentItemStartTime", "currentItemEndTime", "srtType", "currentTranscript"]),
  },
  ...mapMutations(['setSrtType']),
  //第一次访问127.0.0.1:8080的时候是1 0 0，后面切换到其他tab还是swipe都不会再触发
  mounted() {
    //添加SrtList后，点击听力按钮切换时，原代码吐过start/endTime不变，start/endTime会变成0，所以加了这两句代码
    this.startTime = this.currentItemStartTime;
    this.endTime = this.currentItemEndTime
    //console.log("this.startTime", this.startTime)//0
    //console.log("this.endTime", this.endTime)//0
    //console.log("currentTranscript", this.currentTranscript)
    //console.log('TestComponent:mounted()执行了');
    //console.log('TestComponent:mounted:active', this.active)
    //console.log('TestComponent:mounted:当前 item 的 id：', this.currentItemId);
    //console.log('TestComponent:mounted:当前 item 的 startTime：', this.currentItemStartTime);
    //console.log('TestComponent:mounted:当前 item 的 endTime：', this.currentItemEndTime);
  },
  watch: {
    currentTranscript(newVal){
    },
    srtType(newVal){
      this.srtType = newVal
      console.log("this.srtType", this.srtType)
    },
    //切换tab会变化 0 1 2
    // eslint-disable-next-line no-unused-vars
    //active(newValue) {
      //console.log("TestComponent.watch.active.newValue", newValue)
    //},
    //不变化
    // eslint-disable-next-line no-unused-vars
    //currentItemId(newVal) {
      //console.log('currentItemId 发生变化，新值为：', newVal);
    //},
    currentItemStartTime(newVal) {
      this.startTime = newVal;
      // 触发子组件的重新渲染或更新。当currentItemStartTime变化时，立即更新videoStartTime并将变化传递给子组件。
      this.$forceUpdate();
      //console.log("TestComponent:watch:currentItemStartTime.afterNew", this.startTime)
      //console.log('currentItemStartTime 发生变化，新值为：', newVal);
    },
    currentItemEndTime(newVal) {
      //console.log("currentItemStartTime.endTime.beforeNew", this.endTime)
      this.endTime = newVal;
      //console.log("TestComponent:watch:currentItemEndTime.afterNew", this.endTime)
      //console.log('currentItemEndTime 发生变化，新值为：', newVal);
    },
  }
};
</script>

<style scoped>
/*所有元素高度加起来不超过屏幕总高度，当某个元素过长时出现滚动条：
给容器 .container 设置 max-height 为 100vh（表示视口高度），并设置 overflow-y 为 auto，这样当内容超过容器高度时会出现滚动条。
给内部的 .srt 以及其他可能过长的元素也设置适当的 max-height 和 overflow-y 属性来控制其显示。*/
.container {
  max-height: 100vh;
  overflow-y: auto;
}
.srt {
  max-height: 60vh; /* 根据实际情况调整高度 */
  overflow-y: auto;
}
.fixed-right-button {
  position: fixed;
  top: 5px;
  right: 1px;
}
</style>