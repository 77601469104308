<template>
  <div className="video-container">
    <video ref="videoPlayer" className="video-js"></video>
  </div>
</template>

<script>
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import { ref, defineExpose } from 'vue';
import store from "@/store/store";
import {mapMutations,mapState} from "vuex";

export default {
  name: 'VideoPlayer',
  props: {
    options: {
      type: Object,
      default() {
        return {};
      }
    },
    startTime: {
      type: Number,
      default: 0
    },
    endTime: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      player: null,
    }
  },
  mounted() {
    this.initVideoPlayer();
    var srtType = store.getters.srtType
    if (srtType == 'srtCloze'){
      this.setupVideoEndLogic();
    } else {
      this.setupVideoEndLogicList();
    }
  },
  computed: {
    ...mapState(['currentItemCurrentTime']),
    ...mapMutations(['setCurrentItemCurrentTime']),
  },
  methods: {
    setupVideoEndLogicList() {
      this.player.on('play', () => {
        this.player.currentTime(this.startTime);
      });
      this.player.on('timeupdate', () => {
        store.commit("setCurrentItemCurrentTime", this.player.currentTime())
        //console.log("this.player.currentTime()", this.currentItemCurrentTime)
        /*if (this.player.currentTime() >= this.endTime) {
          this.player.pause();
        }*/
      });
    },
    setupVideoEndLogic() {
      this.player.on('play', () => {
        this.player.currentTime(this.startTime);
      });
      this.player.on('timeupdate', () => {
        if (this.player.currentTime() >= this.endTime) {
          this.player.pause();
        }
      });
    },
    initVideoPlayer() {
      this.player = videojs(this.$refs.videoPlayer, this.options, () => {
        //第三个tab就打印三遍
        this.player.log('onPlayerReady', this);
        store.commit("setCurrentPlayerIds", this.player.id_)
        this.player.addClass('vjs-16-9');
        this.player.controlBar.progressControl.disable();
      });
    },
  },
  beforeUnmount() {
    if (this.player) {
      this.player.dispose();
    }
  },
  watch: {
    startTime(newVal) {
      if (this.player && newVal !== this.player.currentTime()) {
        //如果TestComponent视频有active，第三个tab就打印三遍
        //去掉TestComponent中视频的active后，点击第二个tab会有两个id不同的player
        this.player.currentTime(newVal);
        var currentPlayerIdsLength = store.getters.currentPlayerIds.length
        if(this.player.id_ == store.getters.currentPlayerIds[currentPlayerIdsLength - 1]){
          console.log("this.player.id_", this.player.id_)
          this.player.play()
        }
      }
    },
    endTime(newVal) {
      if (this.player) {
        //var currentPlayerIdsLength = store.getters.currentPlayerIds.length
        //if(this.player.id_ == store.getters.currentPlayerIds[currentPlayerIdsLength - 1]){
          this.player.endTime = newVal
        //}
      }
    }
  },
}
</script>

<style>

.video-container {
  width: 60%;
  height: auto;
  margin: 20px;
}

/*.video-js {
  width: 100%;
  height: 100%;
}
.video-container {
  width: 100%;
  height: auto;
  padding-bottom: 56.25%;
  position: relative;
  display: block;
}*/

</style>